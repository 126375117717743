
<template>
  <div
    class="notification-page-heading-banner"
    v-if="showPromo && this.founderPayment.length > 0"
  >
    <div class="ban-text" @click="getList()">
      <i class="fa fa-info-circle"></i><span>{{ promoText }}</span>
    </div>
    <button type="button" class="ban-close" @click="closeBanner()">
      &times;
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Banner",
  data() {
    return {
      numberOfInvites: 0,
      showPromo: false,
      promoText: "",
      roomsLoaded: false,
      vacMessagesLoaded: false,
      containerScrollLoaded: false,
      classesFound: false,
    };
  },
  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("auth", ["founderPayment"]),

    allClassesLoaded() {
    return this.roomsLoaded && this.vacMessagesLoaded && this.containerScrollLoaded;
  }
  },
  methods: {
    ...mapActions("auth", ["getFounderPayment"]),
    getList() {
      this.$router.push({ name: "AllInvites" });
    },
    async addText() {
      await this.getFounderPayment();
      if (this.founderPayment) {
        this.promoText = `There ${
          this.founderPayment.length === 1 ? "is" : "are"
        } ${this.founderPayment.length} invite${
          this.founderPayment.length === 1 ? "" : "s"
        } pending, which requires your action`;
        this.showPromo = true;
      } else {
        this.showPromo = false;
      }
      const shopSide = document.querySelector(".shop-side");
      const mainPageLeftSide = document.querySelector(".main-page-left-side");
      const shopLeftMenu = document.querySelector(".shop-left-menu");
      const teamSide = document.querySelector(".team-side");
      const rooms = document.querySelector(".vac-rooms-container");
      const vacMessages = document.querySelector(".vac-col-messages");
      const containerScroll = document.querySelector(
        ".vac-col-messages .vac-container-scroll"
      );

      var x = window.matchMedia("(max-width: 991px)");

      if (this.showPromo && this.founderPayment.length > 0) {
        if (teamSide) {
          teamSide.style.height = "calc(100vh - 130px)";
          teamSide.style.top = "130px";
        }
        if (shopSide) {
          shopSide.style.height = "calc(100vh - 130px)";
          shopSide.style.top = "130px";
        }
        if (shopLeftMenu) {
          shopLeftMenu.style.height = "calc(100vh - 130px)";
        }
        if (mainPageLeftSide) {
          mainPageLeftSide.style.height = "calc(100vh - 130px)";
        }
        if (rooms && vacMessages && containerScroll) {
          rooms.classList.add("rooms-with-banner");
          vacMessages.classList.add("vac-messages-with-banner");
          containerScroll.classList.add("container-scroll-with-banner");
        }
        if (x.matches && rooms && vacMessages && containerScroll) {
          rooms.classList.remove("rooms-with-banner");
          rooms.classList.remove("rooms-without-banner");
          rooms.classList.add("mobile-rooms-with-banner");
          vacMessages.classList.remove("vac-messages-with-banner");
          vacMessages.classList.remove("vac-messages-without-banner");
          vacMessages.classList.add("mobile-vac-messages-with-banner");
          containerScroll.classList.remove("container-scroll-with-banner");
          containerScroll.classList.remove("container-scroll-without-banner");
          containerScroll.classList.add("mobile-container-scroll-with-banner");
        }
        if (x.matches && shopSide) {
          shopSide.style.height = "calc(100vh - 190px)";
        }
      }
    },
    checkClasses() {
    this.roomsLoaded = !!document.querySelector('.vac-rooms-container');
    this.vacMessagesLoaded = !!document.querySelector('.vac-col-messages');
    this.containerScrollLoaded = !!document.querySelector('.vac-col-messages .vac-container-scroll');

    if (this.allClassesLoaded) {
      this.classesFound = true;
      this.addText(); 
    } else {
      setTimeout(() => {
        this.checkClasses();
      }, 1000); 
    }
    },
    closeBanner() {
      this.showPromo = false;
      const shopSide = document.querySelector(".shop-side");
      const mainPageLeftSide = document.querySelector(".main-page-left-side");
      const shopLeftMenu = document.querySelector(".shop-left-menu");
      const teamSide = document.querySelector(".team-side");
      const rooms = document.querySelector(".vac-rooms-container");
      const vacMessages = document.querySelector(".vac-col-messages");
      const containerScroll = document.querySelector(
        ".vac-col-messages .vac-container-scroll"
      );

      var x = window.matchMedia("(max-width: 991px)");

      if (shopSide) {
        shopSide.style.height = "calc(100vh - 68px)";
        shopSide.style.top = "68px";
      }
      if (teamSide) {
        teamSide.style.height = "calc(100vh - 68px)";
        teamSide.style.top = "68px";
      }
      if (shopLeftMenu) {
        shopLeftMenu.style.height = "calc(100vh - 68px)";
      }
      if (mainPageLeftSide) {
        mainPageLeftSide.style.height = "calc(100vh - 68px)";
      }
      if (rooms && vacMessages && containerScroll) {
        rooms.classList.remove("rooms-with-banner");
        rooms.classList.add("rooms-without-banner");
        vacMessages.classList.remove("vac-messages-with-banner");
        vacMessages.classList.add("vac-messages-without-banner");
        containerScroll.classList.remove("container-scroll-with-banner");
        containerScroll.classList.add("container-scroll-without-banner");
      }
      if (x.matches && rooms && vacMessages && containerScroll) {
        rooms.classList.remove("rooms-without-banner");
        rooms.classList.remove("mobile-rooms-with-banner");
        rooms.classList.add("rooms-with-banner");
        vacMessages.classList.remove("vac-messages-with-banner");
        vacMessages.classList.remove("vac-messages-without-banner");
        vacMessages.classList.remove("vac-messages-with-banner");
        vacMessages.classList.add("mobile-vac-messages-without-banner");
        containerScroll.classList.remove("container-scroll-with-banner");
        containerScroll.classList.remove("container-scroll-without-banner");
        containerScroll.classList.remove("mobile-container-scroll-with-banner");
        containerScroll.classList.add("mobile-container-scroll-without-banner");
      }
    },
    closePromoPrompt() {
      this.showPromo = false;
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     // this.addText();
  //     const roomsLoaded = document.querySelector(".vac-rooms-container");
  //     const vacMessagesLoaded = document.querySelector(".vac-col-messages");
  //     const containerScrollLoaded = document.querySelector(
  //       ".vac-col-messages .vac-container-scroll"
  //     );
  //     this.currentTabUrl = localStorage.getItem("currentUrl");
  //     if (this.currentTabUrl === "/teams" && roomsLoaded && vacMessagesLoaded && containerScrollLoaded) {
  //       this.addText();
  //       console.log("team page")
  //     } else {
  //       this.addText();
  //       console.log("without team page")

  //     }
  //   });
  // },
  mounted() {
  this.$nextTick(() => {
    this.currentTabUrl = localStorage.getItem('currentUrl');
    if (this.currentTabUrl === '/teams') {
      this.checkClasses();
    } else {
        this.addText();
      }
  });
}
};
</script>

<style>
.banner-container {
  margin-top: 50px;
  padding-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.banner {
  background-color: #ffff;
  color: #188ef5;
  padding: 15px;
  border: 1px solid #ffff;
  border-radius: 5px;
  cursor: pointer;
}
</style>
