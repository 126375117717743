var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openFriendProfileModal",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#FriendProfileModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"FriendProfileModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Invite friend to join Who I Follow ")]),_c('button',{ref:"closeFriendProfileModal",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[(_vm.addFriend)?_c('div',[_c('div',{staticClass:"outer-wrap"},[_c('div',{staticClass:"img"},[(_vm.profile.picture == 'yes')?_c('img',{attrs:{"src":_vm.Storage_URL +
                      _vm.profile.user_id +
                      '/profile_picture.jpg?v=' +
                      _vm.time,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/user-default-new.png"),"alt":""}})]),_c('p',{staticClass:"user_link"},[_vm._v(" Looking for "),_c('a',{staticClass:"m-0 text-capitalize"},[_vm._v(_vm._s(_vm.profile.fname)+" "+_vm._s(_vm.profile.lname))]),_vm._v(" ? ")]),_c('p',{staticClass:"user_link"},[_vm._v("Add him as a friend instead")])]),_c('div',{staticClass:"event-btns-wrapper pt-3"},[_c('button',{staticClass:"btn btn-secondary w-50",attrs:{"data-dismiss":"modal"}},[_vm._v("Cancel")]),(this.isDisabled == false)?_c('button',{staticClass:"btn btn-primary w-50",attrs:{"type":"button","block":"","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.add(0, _vm.profile.user_id, _vm.profile)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Add Friend")])]):(this.isDisabled == true)?_c('button',{staticClass:"btn btn-primary-disabled w-50",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Add Friend")])]):_vm._e()])]):_vm._e(),(_vm.viewProfile)?_c('div',[_c('div',{staticClass:"outer-wrap"},[_c('div',{staticClass:"img"},[(_vm.profile.picture == 'yes')?_c('img',{attrs:{"src":_vm.Storage_URL +
                      _vm.profile.user_id +
                      '/profile_picture.jpg?v=' +
                      _vm.time,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/user-default-new.png"),"alt":""}})]),_c('p',{staticClass:"user_name text-capitalize"},[_c('a',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.profile.fname)+" "+_vm._s(_vm.profile.lname))])]),_c('div',{staticClass:"user_link"},[_vm._v("@"+_vm._s(_vm.profile.user_link))])]),_c('div',{staticClass:"event-btns-wrapper pt-3"},[_c('button',{staticClass:"btn btn-secondary w-50",attrs:{"data-dismiss":"modal"}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary w-50",attrs:{"data-dismiss":"modal","block":""},on:{"click":function($event){return _vm.openFriendProfile(_vm.profile)}}},[_vm._v("View Profile")])])]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }