<template>
  <div class="cart-items-heading-wrapper">
    <div v-if="cart.length > 0">
      <div class="items-body">
        <div class="container-fluid no-pdd">
          <div class="row no-gutters">
            <div class="col-6">
              <div class="header">Item</div>
            </div>
            <div class="col-2">
              <div class="header text-center">Price</div>
            </div>
            <div class="col-3">
              <div class="header text-center">Quantity</div>
            </div>
          </div>
          <div
            class="row no-gutters align-items-center mb-3"
            v-for="(item, index) in cart"
            :key="index"
          >
            <div class="col-6">
              <div class="wrapper-info">
                <div class="item-image">
                  <div class="users-notification" v-if="imgLoader == 1">
        <!-- <div class="lds-dual-ring-refresh"></div> -->
        <img src="../../../assets/loader.gif" alt="">
    </div>
                  <img
                  @load="onImageLoad"
                    v-if="item.product.products.image != null"
                    :src="API_URL + item.product.products.image"
                    alt=""
                  />
                  <img@load="onImageLoad"  v-else src="../../../assets/images/gallery.png" alt="" />
                </div>
                <div class="item-infos">
                  <h6>{{ item.product.products.name }}</h6>
                  <p>
                    {{ item.product.catgories.name }} ({{
                      item.product.subcatgories.name
                    }}) <span v-if="item.size">({{ item.size.description }})</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="item-price">
                <span
                  >${{
                    (item.quantity * item.product.products.price).toFixed(2)
                  }}</span
                >
              </div>
            </div>
            <div class="col-3">
              <div class="item-price">
                <img
                  src="../../../assets/images/minus.png"
                  alt=""
                  @click="productdecrement(item.product, item.size)"
                />
                <span>{{ item.quantity }}</span>
                <img
                  src="../../../assets/images/plus.png"
                  alt=""
                  @click="productIncrement(item.product, item.size)"
                />
              </div>
            </div>
            <div class="col-1">
              <button
                class="item-price ml-auto border-0"

                :disabled="isDisabled"
                @click="deleleItem(item.product, item.size)"
              >
                <img src="../../../assets/images/cross.png" alt="" class="remove-cart-btn" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="cart-empty">
      <img src="../../../assets/images/shopping-cart.png" alt="" />
      <p class="head">Empty Cart</p>
      <p class="text">You do not have any product in your shopping cart.</p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
export default {
  name: "ItemsHeading",
  computed: {
    ...mapState("cart", ["cart"]),
  },
  data() {
    return {
      API_URL: this.$productImageStorageURL,
      isDisabled: false,
      imgLoader: 1,
    };
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    ...mapActions({
      removeProductFromCart: "cart/removeProductFromCart",
      increaseQuantityFromCart: "cart/increaseQuantityFromCart",
      decreaseQuantityFromCart: "cart/decreaseQuantityFromCart",
    }),
    productIncrement(item, size) {
      const formData = new FormData();
      formData.append("product_id", item.products.id);
      formData.append("product_size", size.id);
      formData.append("product_quantity", 1);
      axios
        .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.increaseQuantityFromCart({
              product: item,
              size: size,
            });

            this.$root.$emit("checkProductDetailsAgain");
            this.flashMessage.success({
              title: "Success",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          } else {
            this.flashMessage.error({
              title: "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
            this.removeProductFromCart({
              product: item,
              size: size,
            });
            if (this.cart.length == 0) {
              this.$router.go("/shop");
            }
          }
        });
    },

    productdecrement(item, size) {
      const formData = new FormData();
      formData.append("product_id", item.products.id);
      formData.append("product_size", size.id);
      formData.append("product_quantity", -1);
      axios
        .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.decreaseQuantityFromCart({
              product: item,
              size: size,
            });
            if (this.cart.length == 0) {
              this.$router.go("/shop");
            }
            this.$root.$emit("checkProductDetailsAgain");
            this.flashMessage.success({
              title: "Success",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          } else {
            this.flashMessage.error({
              title: "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
            this.removeProductFromCart({
              product: item,
              size: size,
            });
            if (this.cart.length == 0) {
              this.$router.go("/shop");
            }
          }
        });
    },
    deleleItem(item, size) {
      this.isDisabled = true;
      const formData = new FormData();
      formData.append("product_id", item.products.id);
      formData.append("product_size", size.id);
      formData.append("product_quantity", 0);
      axios
        .post(process.env.VUE_APP_API_URL + "shop/addToCart", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            // this.decreaseQuantityFromCart({
            //   product: item,
            //   size: size,
            // });
            this.removeProductFromCart({
              product: item,
              size: size,
            });
            this.isDisabled = false;
            if (this.cart.length == 0) {
              this.$router.go("/shop");
            }
            this.$root.$emit("checkProductDetailsAgain");
            this.flashMessage.success({
              title: "Success",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
          } else {
            this.flashMessage.error({
              title: "Error",
              message: response.data.message,
              time: 5000,
              blockClass: "custom-block-class",
            });
            // this.decreaseQuantityFromCart({
            //   product: item,
            // });
            this.removeProductFromCart({
              product: item,
              size: size,
            });
            this.isDisabled = false;
            if (this.cart.length == 0) {
              this.$router.go("/shop");
            }
          }
        });
    },
  },
};
</script>
