<template>
  <div>
    <div
      class=""
      data-target="#walletModal"
      data-toggle="modal"
      ref="openwalletModal"
    ></div>
    <!-- wallet Modal -->
    <div
      id="walletModal"
      aria-hidden="true"
      aria-labelledby="walletModal"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content" v-if="tabToHistory == true">
          <div class="modal-header">
            <h6>Wallet History.</h6>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="order-history">
              <table class="table table-hover" v-if="records">
                <thead>
                  <tr>
                    <th scope="col">Refferal Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Payable ($)</th>
                    <th scope="col">Package Name</th>
                  </tr>
                </thead>
                <tbody v-for="(record, index) in records" :key="index">
                  <tr>
                    <td scope="row">{{ record.ref_user_name }}</td>
                    <td>{{ record.created_at | formatDateOfBirth }}</td>
                    <td class="text-capitalize">
                      ${{ record.payable.toFixed(2) }}
                    </td>
                    <td>{{ record.package_name }}</td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation example" style="float: right">
                <ul class="pagination m-0">
                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-if="page != 1"
                      @click="page--"
                    >
                      Previous
                    </button>
                  </li>
                  <li class="page-item" v-if="page != 1">
                    <button
                      type="button"
                      class="page-link"
                      v-for="pageNumber in pages.slice(page - 1, page + 5)"
                      :key="pageNumber"
                      @click="page = pageNumber"
                    >
                      {{ pageNumber }}
                    </button>
                  </li>
                  <li class="page-item">
                    <button
                      type="button"
                      @click="page++"
                      v-if="page < pages.length"
                      class="page-link"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              <div v-if="records.length == 0" class="info-heading">
                <p class="text-center" v-if="showLoader == 0">
                  No history found.
                </p>
                <br />
              </div>
              <div class="order-history" v-if="showLoader == 1">
                <div class="container-fluid products-list">
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading />
                  </content-placeholders>
                  <br />
                  <br />
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading />
                  </content-placeholders>
                  <br />
                  <br />
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading />
                  </content-placeholders>
                  <br />
                  <br />
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading />
                  </content-placeholders>
                  <br />
                  <br />
                  <content-placeholders :rounded="true">
                    <content-placeholders-heading />
                  </content-placeholders>
                  <br />
                  <br />
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center w-100 mt-4"></div>
          </div>
        </div>
        <div class="modal-content" v-if="tabToHistory == false">
          <div class="modal-header">
            <h6>How it works.</h6>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="wallet-text">
              <div class="walletDiv">
                <p class=" text-center">
                  Buy "Pro Founder" Package for commision eligibility and after
                  Purchase you just have to invite users to enrole by your
                  invitation link and purchase any package from "Go Pro" then a
                  certain percentage of that package will be added to your
                  wallet.
                </p>
              </div>
              <div class=" walletDiv">
                <p class=" text-center">
                  If you already have "Pro Founder" Package purchased you just
                  need to have people joined Who i follow under your link and
                  have them purchase any of the "Go Pro" package to have your
                  first commsion in your wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FeedBack Response Modal -->
  </div>
  <!--    end feedback-->
</template>

<script>
import axios from "axios";
export default {
  name: "walletHistory",
  data() {
    return {
      errorMessage: null,
      orders: [],
      showLoader: 0,
      page: 1,
      perPage: 12,
      pages: [],
      tabToHistory: false,
    };
  },
  methods: {
    async fetchHistory(page = 1) {
      this.showLoader = 1;
      var user_id = localStorage.getItem("userId");
      axios
        .get(
          `${process.env.VUE_APP_API_URL}wallet/history?page=${page}&user_id=${user_id}`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.orders = response.data.data;
            this.showLoader = 0;
            this.setPages();
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        })
        .catch((error) => {
           if (error)
           {
            if (error.response.status === 401) {
                  localStorage.removeItem("authToken");
                  localStorage.removeItem("userId");
                  localStorage.removeItem("userName");
                  localStorage.removeItem("user_link");
                  localStorage.removeItem("pro_status");
                  localStorage.removeItem("pro_expiry");
                  window.location.reload();
            }
           }
         
        });
    },
    setPages() {
      let numberOfPages = Math.ceil(this.orders.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(orders) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return orders.slice(from, to);
    },
  },
  computed: {
    records() {
      return this.paginate(this.orders);
    },
  },
  mounted() {
    this.$root.$on("walletHistory", (tabsH) => {
      var elem = this.$refs.openwalletModal;
      elem.click();
      this.tabToHistory = tabsH;
    });
    this.$root.$on("walletHistoryAgain", () => {
      this.orders = [];
      this.page = 1;
      this.perPage = 12;
      this.pages = [];
      this.fetchHistory();
    });
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  created() {
    this.fetchHistory();
  },
};
</script>

<style>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 14px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
</style>
