<template>
  <div>
    <div class="user-notification" v-if="notificationList.length > 0">
      <div
        class="notification-content"
        v-for="list in notificationList"
        :key="list.id"
        v-bind:class="[list.read == 1 ? 'active-notification' : '']"
      >
        <div class="user-img" :id="list.message_type">
            <!-- Message Type 19 - Courthouse Image -->
            <a v-if="list.message_type == '19'" class="m-0">
                <img class="mr-3" src="../../assets/images/courthouse-1.png" alt="Courthouse" />
            </a>

            <!-- Message Type 8 - Sender's Profile Image -->
            <a v-else-if="list.message_type == '8'" class="m-0">
                <img :src="API_URL_PRODUCT + list.sender_picture" alt="" />
            </a>

            <!-- Router Link for Custom Notification False/0 -->
            <router-link
                v-else-if="list.send_by != 1 && (list.custom_notification === false || list.custom_notification === 0)"
                :to="'/profile/' + list.sender_link"
                exact
                class="m-0"
                @click="readNotificationAndUser(list)"
            >
                <div class="users-notification" v-if="imgLoader == 1">
                    <img src="../../assets/loader.gif" alt="">
                </div>
                <img
                    @load="onImageLoad"
                    v-if="list.sender_picture == 'yes'"
                    :src="API_URL + list.send_by + '/profile_picture.jpg?v=' + time"
                    alt=""
                />
                <img @load="onImageLoad" v-else src="../../assets/images/user-default-new.png" alt="" />
            </router-link>

            <!-- Custom Notification True/1 -->
            <div v-else-if="list.custom_notification === true || list.custom_notification === 1">
                <img @load="onImageLoad" src="../../assets/images/user-default-logo.png" alt="" />
            </div>

            <!-- Default User Image for Send By 1 -->
            <img @load="onImageLoad" v-else-if="list.send_by == 1" src="../../assets/images/user-default-new.png" alt="" />
        </div>

        <div class="user-content" v-if="list.custom_notification === false || list.custom_notification === 0">
          <!-- Render these elements if message_type is not 18 or 19 -->
          <span class="bold" @click="readNotification(list)">
            <a style="color: black !important; font-size: 14px" 
              class="m-0 text-capitalize" 
              v-if="!(list.message_type === '18' || list.message_type === 18 || list.message_type === '19' || list.message_type === 19) && (list.custom_notification !== true && list.custom_notification !== 1)">
                {{ list.sender_name }}
            </a>

          </span>
          <span @click="readNotification(list)">{{ list.message }}</span>
          <p @click="readNotification(list)" >
            {{ list.created_at | formatTimeAgo }}
          </p>
        </div>

        <div v-if="list.custom_notification === true || list.custom_notification === 1" class="user-content">
          <span class="bold">
          </span>
          <div class="custom-notification">
            <span class="subject mb-0 pb-1">{{ list?.subject }}</span>
            <span class="cursor-none mb-0 pb-1">{{ list.description || list.body }}</span>
          </div>
          <p class="cursor-none mt-0">
            {{ list.created_at | formatTimeAgo }}
          </p>
        </div>

      </div>
    </div>
    <div class="user-notification" v-else-if="showLoader == 0">
      <p>No notification found.</p>
    </div>

    <div class="user-notification d-flex align-items-center justify-content-center" v-if="showLoader == 1">
        <div class="lds-dual-ring-refresh"></div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
// import axios from "axios";
export default {
  name: "NotificationContent",
  props: {
    showLoader: {
      type: Number,
    },
    notificationList: {
      type: Array,
    },
  },
  data() {
    return {
      imgLoader: 1,
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      API_URL_PRODUCT: this.$productImageStorageURL,
    };
  },
  methods: {
    ...mapActions("notification", ["setCounterRead"]),
    ...mapActions("auth", ["getGrpData"]),
    readNotification(list) {
      this.setCounterRead(list.id);
      if (list.message_type == "5" || list.message_type == 5) {
        localStorage.setItem("post_id_notify", list.table_id);
        window.location.pathname == "/"
          ? window.location.reload()
          : this.$router.push({ name: "Home" });
      } else if (
        list.message_type == "9" ||
        list.message_type == "10" ||
        list.message_type == 9 ||
        list.message_type == 10
      ) {
        localStorage.setItem("post_id_single", list.table_id);
        window.location.pathname == "/post"
          ? window.location.reload()
          : this.$router.push({ name: "SinglePost" });
      } else if (list.message_type == "3" || list.message_type == 3) {
        localStorage.setItem("openFriends", "request");
        window.location.pathname == "/friends"
          ? window.location.reload()
          : this.$router.push({ name: "FriendPage" });
      } else if (list.message_type == "8" || list.message_type == 8) {
        window.location.pathname == `/product-detail/${list.table_id}`
          ? window.location.reload()
          : this.$router.push(`/product-detail/${list.table_id}`);
      } else if (
        list.message_type == "12" ||
        list.message_type == 12 ||
        list.message_type == "13" ||
        list.message_type == 13 ||
        list.message_type == 15 ||
        list.message_type == 16 ||
        list.message_type == "15" ||
        list.message_type == "16"
      ) {
        window.location.pathname == "/teams"
          ? window.location.reload()
          : this.$router.push({ name: "Teams" });
      } else if (list.message_type == "11" || list.message_type == 11) {
        // var formData = new FormData();
        // formData.append("sendLinkUrl", list.table_id ? list.table_id : "");
        // return axios
        //   .post(process.env.VUE_APP_API_URL + "invite/readLogin", formData)
        //   .then((response) => {
        //     if (response.data.statusCode == 200) {
        //       this.grpInfo = response.data.data.userByCopy;
        //       if (this.grpInfo == null || this.grpInfo.length == 0) {
        //         this.$router.push({ name: "Home" });
        //       } else {
        //         this.getGrpData(this.grpInfo);
        //         localStorage.setItem("linkUrl", list.table_id);
        //         localStorage.setItem("notifyId", list.id);
               window.location.pathname == "/pending-invites"
          ? window.location.reload()
          : this.$router.push({ name: "PendingInvites" });
          //     }
          //   } else {
          //     setTimeout(() => {
          //       this.errorMessage = response.data.message;
          //     }, 500);
          //   }
          // });
        //  window.location.pathname == "/teams"
        //     ? window.location.reload()
        //     : this.$router.push({ name: "Teams" });
      }else if (list.message_type == "17" || list.message_type == 17) {
        if(list.payment == 1){
          window.location.pathname == "/package-Invites"
          ? window.location.reload()
          : this.$router.push({ name: "AllInvites" });
        }else{
          this.$router.push({ name: "PageNotFound" });
        }
               
      }else if (list.message_type == "18" || list.message_type == 18) {
          window.location.pathname == "/user-invites"
          ? window.location.reload()
          : this.$router.push({ name: "UserInvites" });
               
      } else if (list.message_type == "19" || list.message_type == 19) {
          window.location.pathname == "/association-invites"
          ? window.location.reload()
          : this.$router.push({ name: "AssociationInvites" });
               
      } else {
        window.location.pathname == "/friends"
          ? window.location.reload()
          : this.$router.push({ name: "FriendPage" });
      }
    },
    readNotificationAndUser(list) {
      this.setCounterRead(list.id);
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
  },
};
</script>
