<template>
  <div class="cart-top">
    <div class="cart-heading">
      <h6>Shopping Cart</h6>
    </div>
    <div class="cart-items" v-if="cartItemCount >= 1">
      <span>{{ cartItemCount }} ITEM{{ cartItemCount > 1 ? "S" : "" }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CartHeading",
  computed: {
    ...mapGetters("cart", ["cartItemCount"]),
  },
};
</script>