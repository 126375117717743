<template>
  <div>
    <FriendProfileModal />
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FriendsInvitationS"
      ref="openModalInvite"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="FriendsInvitationS">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Invite Friend to join Who I Follow</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalInviteFriend"
              @click="showscroll()"
            >
              <img src="../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <p class="tag-line mb-3">Let's invite your friends.</p>
            <p v-if="errorMessage != null" class="tag-line text-danger mb-2">
              {{ errorMessage }}
            </p>
            <form @keyup.enter="sendInvites(0)" @submit.prevent>
              <div class="d-md-flex align-items-center">
                <div class="phone-code">
                  <b-form-select v-model="code" :options="codeList">
                    <template #first> </template>
                  </b-form-select>
                </div>

                <div class="form-group ml-md-4 mt-3 mt-md-0 phone-number mb-0">
                  <div class="input-wrapper">
                    <input
                      id="mob"
                      class="mb-0 form-control"
                      v-model.trim="$v.details.mob.$model"
                      spellcheck="false"
                      autocomplete="off"
                      :class="{
                        'is-invalid': validationStatus($v.details.mob),
                      }"
                      trim
                      required
                      type="text"
                      v-on:keyup="errorNull"
                    />

                    <span class="placeholder">Mobile Number</span>
                    <div v-if="!$v.details.mob.integer" class="invalid-tooltip">
                      Mobile number should be number.
                    </div>
                    <div
                      v-else-if="!$v.details.mob.minLength"
                      class="invalid-tooltip"
                    >
                      Minimum length should be 10.
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center my-3">OR</div>
              <p
                v-if="errorMessageEmail != null"
                class="tag-line text-danger mb-2"
              >
                {{ errorMessageEmail }}
              </p>

              <div class="form-group">
                <div class="input-wrapper">
                  <input
                    id="email"
                    class="form-control"
                    v-model.trim="$v.details.email.$model"
                    spellcheck="false"
                    autocomplete="off"
                    :class="{
                      'is-invalid': validationStatus($v.details.email),
                    }"
                    trim
                    required
                    type="text"
                    v-on:keyup="errorNull"
                  />

                  <span class="placeholder">Email</span>
                  <div v-if="!$v.details.email.email" class="invalid-tooltip">
                    Please provide valid email
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              block
              @click="sendInvites(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Send Invite</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Send Invite</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { email, minLength, integer } from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import FriendProfileModal from "./FriendProfileModal.vue";

export default {
  name: "Invite-Friends-Modal",
  data() {
    return {
      isDisabled: true,
      errorMessage: null,
      errorMessageEmail: null,
      successMessage: null,
      isLoadingArray: [],
      code: "+1",
      details: {
        mob: "",
        email: "",
      },
      codeList: [
        { value: "+92", text: "Pak (+92)" },
        { value: "+1", text: "USA (+1)" },
      ],
    };
  },
  components: {
    FriendProfileModal,
  },
  validations: {
    details: {
      email: { email },
      mob: { integer, minLength: minLength(10) },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    this.$root.$on("openInviteFriendsModal", () => {
      var elem = this.$refs.openModalInvite;
      if(elem)
      {
          elem.click();
      }
      
    });
  },

  methods: {
    ...mapActions("auth", ["sendRegisterRequest"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    sendInvites: function (index) {
    this.isDisabled = true;
    this.$v.$touch();
    if (this.$v.$pending || this.$v.$error) return;
    this.$set(this.isLoadingArray, index, true);

    const data = {
      ...this.details,
      code: this.code
    };

    return axios.post(process.env.VUE_APP_API_URL + "invite/sendSocialInvite", data, {})
      .then((response) => {
        if (response.data.statusCode == 200) {
          this.details.mob = "";
          this.details.email = "";
          this.code = "+1";
          this.successMessage = response.data.message;
          this.$alert("Invitation has been sent successfully.");
        } else {
          if (
            response.data.message == "Email already registered" ||
            response.data.message == "Mobile number already registered"
          ) {
            this.errorMessageEmail = response.data.message;
            this.callModal(
              response.data.data.friend_status,
              response.data.data.userFound
            );
          } else {
            this.errorMessage = response.data.message;
          }
        }
      })
      .catch(() => {
        this.$alert("Failed to send the invitation. Please try again later.");
      })
      .finally(() => {
        this.$set(this.isLoadingArray, index, false);
      });
  },
    callModal(friend_status, profile) {
      var elem = this.$refs.closeModalInviteFriend;
      if (elem) {
        elem.click();
      }
      this.$root.$emit("FriendProfileModal",friend_status,profile);
    },
    errorNull: function () {
      this.errorMessage = null;
      this.errorMessageEmail = null;
      if (
        (this.details.mob == "" && this.details.email == "") ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },

    dataReset: function () {
      this.errorMessage = null;
      this.errorMessageEmail = null;
      this.details.mob = "";
      this.details.email = "";
      this.code = "+1";
      this.isDisabled = true;
    },

    showscroll: function () {
      // document.querySelector("body").style.overflow = "visible";
    },
  },
};
</script>
