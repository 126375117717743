<template>
  <div class="summary-wrapper">
    <div class="summary-heading">
      <h6>Summary</h6>

      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        ref="closeModalShoppingCart"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div>
      <div class="summary-detail">
        <div class="summary-heading-wrapper">
          <div class="sub-total">
            <span>Subtotal</span>
          </div>
          <!-- <div class="shipping">
              <span>Shipping</span>
            </div>
            <div class="tax">
              <span>Tax</span>
            </div> -->
        </div>
        <div class="summary-content-wrapper">
          <div class="sub-total-price">
            <span>${{ cartTotalPrice.toFixed(2) }}</span>
          </div>
          <!-- <div class="shipping-price">
              <span>$0</span>
            </div>
            <div class="tax-price">
              <span>$0</span>
            </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="summary-code">
          <div class="code-heading">
            <span>Promocode</span>
          </div>
          <div class="code-content">
            <span>enter code</span>
          </div>
        </div> -->
    <div class="summary-total">
      <div class="total-heading">
        <span>Total</span>
      </div>
      <div class="total-content">
        <span>${{ cartTotalPrice.toFixed(2) }}</span>
      </div>
    </div>
    <div
      v-if="cartItemCount > 0"
      class="summary-btn"
      ref="closeModalShoppingCart"
      data-dismiss="modal"
    >
      <div @click="backToOrder" class="cart-link">Checkout</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Summary",
  computed: {
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapGetters("cart", ["cartItemCount"]),
  },
  methods: {
    backToOrder: function() {
      var elem = this.$refs.closeModalShoppingCart;
      elem.click();
      this.$router.push({ name: "Order" });
    },
  },
};
</script>
