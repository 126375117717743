<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FriendProfileModal"
      ref="openFriendProfileModal"
    ></button>

    <div
      class="modal fade"
      id="FriendProfileModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Invite friend to join Who I Follow
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeFriendProfileModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="addFriend">
              <div class="outer-wrap">
                <div class="img">
                  <img
                      v-if="profile.picture == 'yes'"
                      :src="
                        Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                      "
                      alt=""
                    />
                  <img v-else src="../../assets/images/user-default-new.png" alt="" />
                </div>
                <p class="user_link">
                  Looking for <a class="m-0 text-capitalize">{{ profile.fname }} {{ profile.lname }}</a> ?
                </p>
                <p class="user_link">Add him as a friend instead</p>
              </div>
              <div class="event-btns-wrapper pt-3">
                <button data-dismiss="modal" class="btn btn-secondary w-50">Cancel</button>
                <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-50"
              block
              @click="add(0, profile.user_id, profile)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Friend</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-50"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Add Friend</span>
            </button>
              </div>
            </div>
            <div v-if="viewProfile">
              <div class="outer-wrap">
                <div class="img">
                 <img
                      v-if="profile.picture == 'yes'"
                      :src="
                        Storage_URL +
                        profile.user_id +
                        '/profile_picture.jpg?v=' +
                        time
                      "
                      alt=""
                    />
                  <img v-else src="../../assets/images/user-default-new.png" alt="" />
                </div>
                <p class="user_name text-capitalize"><a class="m-0">{{ profile.fname }} {{ profile.lname }}</a></p>
                <div class="user_link">@{{ profile.user_link }}</div>
              </div>
              <div class="event-btns-wrapper pt-3">
                <button data-dismiss="modal" class="btn btn-secondary w-50">Cancel</button>
                <button data-dismiss="modal" class="btn btn-primary w-50" block @click="openFriendProfile(profile)">View Profile</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "FriendProfileModal",
  data() {
    return {
      addFriend: false,
      viewProfile: false,
      Storage_URL: this.$userProfileStorageURL,
      profile:[],
      time: this.$time,
      isLoadingArray: [],
      errorMessage: null,
      isDisabled: false,
    };
  },
  methods: {
    openFriendProfile(profile) {
      var elem = this.$refs.closeFriendProfileModal;
      if (elem) {
        elem.click();
      }
      this.$router.push(`/profile/${profile.user_link}`)
    },
    async add(index, friend_id, profile) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);
      $("#" + friend_id).hide();
      var formData = new FormData();
      formData.append("friend_id", friend_id);
      return axios
        .post(process.env.VUE_APP_API_URL + "friends/addFriend", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.statusCode == 200) {
            this.$set(this.isLoadingArray, index, true);
             var elem = this.$refs.closeFriendProfileModal;
      if (elem) {
        elem.click();
      }
            this.$router.push(`/profile/${profile.user_link}`);
            this.isDisabled = false;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    }
  },
  mounted() {
    this.$root.$on("FriendProfileModal", (friend_status,profile) => {
      this.profile=profile;
      if(friend_status==1)
      {
        this.viewProfile=true;
        this.addFriend=false;
      }
      else
      {
        this.viewProfile=false;
        this.addFriend=true;
      }
      var elem = this.$refs.openFriendProfileModal;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>

<style>
</style>