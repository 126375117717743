<template>
  <div class="notification-wrapper">
    <div class="notification-top">
      <h4>Notifications</h4>
      <router-link to="/notifications" class="m-0">View all</router-link>
    </div>
    <NotificationContent
      :notificationList="notificationList"
      :showLoader="showLoader"
    />
  </div>
</template>
<script>
// import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import NotificationContent from "../../components/Notification/NotificationContent.vue";
export default {
  name: "Notification",
  components: {
    NotificationContent,
  },
  data() {
    return {
      showLoader: 1,
    };
  },
  computed: {
    ...mapGetters("notification", ["notificationList"]),
  },
  methods: {
    ...mapActions("notification", ["getUserNotificationData"]),

    getNotificationList() {
      if (this.notificationList.length == 0) {
        this.getUserNotificationData().then((response) => {
          if (response.data.statusCode == 200) {
            this.showLoader = 0;
          } else {
            this.showLoader = 0;
          }
        });
      } else {
        this.showLoader = 0;
      }
    },
  },

  mounted() {
    this.getNotificationList();
  },
};
</script>
<style></style>
