<template>
  <div>
    <InviteFriendsModal />
    <div class="left-menu-wrapper">
      <ul class="menu">
        <li class="item">
          <router-link
            class="item-link"
            exact
            to="/"
          >
            <div class="img-wraper"><img :src="imageHome" alt="" /></div>
            <span class="tooltiptext">Opinions</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/friends">
            <div class="img-wrapper"><img :src="imageFriends" alt="" /></div>
            <span class="tooltiptext">Friends</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/pros">
            <div class="img-wrapper"><img :src="imageSpecialist" alt="" /></div>
            <span class="tooltiptext">Specialists</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/order-history">
            <div class="img-wrapper">
              <img :src="imageOrderHistory" alt="" />
            </div>
            <span class="tooltiptext">Orders</span>
          </router-link>
        </li>
        <li class="item">
          <a
            class="item-link feedback-btn"
            data-target="#feedBackModal"
            data-toggle="modal"
          >
            <div class="img-wrapper">
              <img src="../../assets/images/feedback-grey.png" alt="" />
            </div>
            <span class="tooltiptext">Feedback</span>
          </a>
        </li>
        <!-- <li class="item">
          <router-link class="item-link" exact to="/box-scores">
            <img :src="boxScore" alt="" />
            <span class="tooltiptext">Box Scores</span>
          </router-link>
        </li>
        <li class="item">
          <router-link class="item-link" exact to="/live-stream">
            <img :src="liveStream" alt="" />
            <span class="tooltiptext">Live Stream</span>
          </router-link>
        </li> -->
        <!-- <li class="item text-center">
          <router-link class="item-link" exact to="/score-game">
            <img :src="boxScore" alt="" />
            <span class="tooltiptext">Single Box Score</span>
          </router-link>
        </li> -->
      </ul>
      <ul class="menu">
        <li class="item mx-2">
          <button
            class="btn btn-primary w-100"
            @click="callModal()"
          >
            <div class="img-wrapper">
              <img src="../../assets/images/addfriend.png" alt="" />
            </div>
            <span class="tooltiptext text-white">Invite</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InviteFriendsModal from "./InviteFriendsModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  data() {
    return {
      currentTabUrl: "",
      imageHome: require("../../assets/images/opinion-grey.png"),
      homeBlue: require("../../assets/images/firstline.png"),
      homeGrey: require("../../assets/images/opinion-grey.png"),
      imageSpecialist: require("../../assets/images/specialist-grey.png"),
      imageOrderHistory: require("../../assets/images/order-grey.png"),
      specialistsGrey: require("../../assets/images/specialist-grey.png"),
      specialistsBlue: require("../../assets/images/specialist.png"),
      orderHistoryGrey: require("../../assets/images/order-grey.png"),
      orderHistoryBlue: require("../../assets/images/order.png"),
      friendsBlue: require("../../assets/images/friends.png"),
      friendsGrey: require("../../assets/images/friend-grey.png"),
      imageFriends: require("../../assets/images/friend-grey.png"),
      eventsBlue: require("../../assets/calender.png"),
      eventsGrey: require("../../assets/calender-grey.png"),
      boxScore: require("../../assets/images/box-score-grey.png"),
      boxBlue: require("../../assets/images/boxscore.png"),
      boxGrey: require("../../assets/images/box-score-grey.png"),
      liveStreamBlue: require("../../assets/images/wifi-square-active.png"),
      liveStreamGrey: require("../../assets/images/wifi-square.png"),
      liveStream: require("../../assets/images/wifi-square.png"),
    };
  },
  computed: {
    ...mapGetters("auth", ["subscribedInfo"]),
  },
  components: {
    InviteFriendsModal,
  },
  methods: {
    callModal: function () {
      this.$root.$emit("openInviteFriendsModal");
      // document.querySelector("body").style.overflow = "hidden";
    },
    imageSwap() {
      this.currentTabUrl = localStorage.getItem("currentUrl");
      
      if (this.currentTabUrl == "/") {
        this.imageTeam = this.teamGrey;
        this.imageHome = this.homeBlue;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      }
      else if (this.currentTabUrl == "/pros") {
        this.imageTeam = this.teamGrey;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsBlue;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/order-history") {
        this.imageTeam = this.teamGrey;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryBlue;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/friends") {
        this.imageTeam = this.teamGrey;
        this.imageHome = this.homeGrey;
        this.imageShop = this.shopGrey;
        this.imageEvents = this.eventsGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryGrey;
        this.imageFriends = this.friendsBlue;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/box-scores") {
        this.boxScore = this.boxBlue;
        this.imageShop = this.shopGrey;
        this.imageHome = this.homeGrey;
        this.imageTeam = this.teamGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryGrey;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamGrey;
      } else if (this.currentTabUrl == "/live-stream") {
        this.boxScore = this.boxGrey;
        this.imageShop = this.shopGrey;
        this.imageHome = this.homeGrey;
        this.imageTeam = this.teamGrey;
        this.imageSpecialist = this.specialistsGrey;
        this.imageOrderHistory = this.orderHistoryGrey;
        this.imageFriends = this.friendsGrey;
        this.liveStream = this.liveStreamBlue;
      }
    },
  },
  mounted() {
    this.imageSwap();
  },
};
</script>
