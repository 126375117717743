<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ProductCart"
      ref="openModalProductCart"
    ></button>
    <div
      class="modal fade"
      id="ProductCart"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog product-cart-modal" role="document">
        <div class="modal-content">
          <div class="modal-left-wrapper">
            <CartHeading />
            <ItemsHeading />
          </div>
          <div class="modal-right-wrapper">
            <Summary />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions,mapState } from "vuex";
import CartHeading from "../ShoppingCart/CartHeading.vue";
import ItemsHeading from "../ShoppingCart/ItemsHeading.vue";
import Summary from "../ShoppingCart/Summary.vue";
import axios from "axios";
import $ from "jquery";
export default {
  components: {
    CartHeading,
    ItemsHeading,
    Summary,
  },
  name: "ProductCartModal",
  computed: {
    ...mapGetters("cart", ["cartItemCount"]),
    ...mapState("cart", ["cart"]),
  },
  data() {
    return {
      cartUp: "",
      cartMessage: "",
    };
  },
  methods: {
    ...mapActions({
      addProductToCart: "cart/addProductToCart",
    }),
    ...mapActions({
      getTaxShippingData: "cart/getTaxShippingData",
    }),
    backToOrder: function () {
      var elem = this.$refs.closeModalShoppingCart;
      elem.click();
      this.$router.push({ name: "Order" });
    },
    async getUserCart() {
      if (this.cart.length == 0) {
        let cartResults = await axios.get(`shop/userCart`);
        if (cartResults.data.statusCode == 200) {
          this.cartUp = cartResults.data.data;
          this.cartUp.forEach((value) => {
            const product = {};
            product.products = value.products;
            product.catgories = value.products.category.parent;
            product.subcatgories = value.products.category;
            this.addProductToCart({
              product: product,
              quantity: value.product_quantity,
              size: value.product_size
            });
          });
        } else {
          this.cartMessage = cartResults.data.message;
        }
      }
    },
  },
  mounted() {
    this.getUserCart(1);
    this.getTaxShippingData();
    $(window).on("popstate", function () {
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
    });
    this.$store.commit("setErrors", {});
    this.$root.$on("openProductCartModal", () => {
      var elem = this.$refs.openModalProductCart;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
